<template>
	<div>
		<van-nav-bar
			title="米因大數據疫苗卡"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="card">
			<div class="top"></div>
			<div :class="isTrue ? 'form_header opacity_box' : 'form_header'">
				<div class="">
					<van-image class="avatar" :src="require('@/assets/images/vaccines/touxiang.png')"></van-image>
				</div>
				<div class="form_header_title">請填寫疫苗卡密碼</div>
			</div>
			<div :class="isTrue ? 'form_wrap opacity_box' : 'form_wrap'">
				<div class="form_item_code">
					<div class="form_item_label">疫苗卡密碼：</div>
					<van-field
						placeholder="请填写"
						class="form_item_input"
						type="text"
						v-model="code"
					/>
				</div>
			</div>
			<div :class="isTrue ? 'foot opacity_box' : 'foot'"> 
				<van-button class="btn" @click="handleSub">下一步</van-button>
			</div>
			
			<div class="popup" v-if="isTypeShow">
				<div class="popup_wrap">
					
					<div class="type_lab">該疫苗卡為：</div>
					<div class="type_val">{{typeVal}}</div>
					
					<div class="btn_cell">
						<van-button class="popup_btn_cal" @click="handleClose">取消</van-button>
						<van-button class="popup_btn_sub" @click="handleSubOrder">確認</van-button>
					</div>
		
					<div class="popup_close" @click="handleClose">
						<van-image class="close_icon" :src="require('@/assets/images/vaccines/close_icon.png')"></van-image>
					</div>
				</div>
			</div>

			
			
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog, Field } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Field)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesGoods',
		data (){
			return {
				isTrue: false,
				isTypeShow: false,
				typeVal: '',
				code: ''
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			
			setTimeout(() => {
				this.isTrue = true
			}, 100);
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			handleToPay() {
				
			},
			handleSub() {
				this.isTypeShow = true;
			},
			handleClose() {
				this.isTypeShow = false;
			},
			handleSubOrder() {
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.card{
		width: 100%;
		min-height: 100vh;
		padding: 1.52rem 0.30rem;
		background-color: #F5F6FA;
	}
	
	.top {
	    position: fixed;
	    top: 0.89rem;
	    left: 0;
	    width: 100%;
	    height: 0.06rem;
	    background-color: #D9D9D9;
	    z-index: 99;
	}
	.top::before {
	    position: absolute;
	    left: 0;
	    top: 0;
	    content: '';
	    width: 50%;
	    height: 0.06rem;
	    background-color: #6681FA;
	}
	
	
	.form_header {
	    width: 100%;
	    display: flex;
	    align-items: center;
	}
	.avatar {
	    width: 0.96rem;
	    height: 0.96rem;
	}
	.form_header_title {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.40rem;
	    padding: 0.32rem;
	    background-color: #fff;
	    border-radius: 0 0.16rem 0.16rem 0.16rem;
	    margin-left: 0.16rem;
	}
	.form_wrap {
	    width: 100%;
	    padding: 0.30rem;
	    margin-top: 0.60rem;
	    border-radius: 0.16rem;
	    background-color: #fff;
	}
	.form_item {
	
	}
	.form_item_code {
	    /* margin-top: 32rem; */
	    margin-bottom: 0.08rem;
	}
	.form_item_label {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.48rem;
		text-align: left;
		margin-bottom: 0.1rem;
	}
	.form_item_input {
	    width: 100%;
	    height: 0.74rem;
	    padding: 0 0.16rem;
	    border-radius: 0.08rem;
	    border: 0.01rem solid #D7DBE1;
		display: flex;
		align-items: center;
	}
	.tips {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FA5151;
	    line-height: 0.30rem;
	}
	
	.foot {
	    width: 100%;
	    margin-top: 0.92rem;
	}
	.btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.16rem;
	}
	
	
	.card .form_header,
	.card .form_wrap,
	.card .foot{
		opacity: 0;
		transform: translateY(100%);
		transition-property: transform,opacity;
		transition-duration: .4s;
	}
	.opacity_box {
	    opacity: 1 !important;
	}
	.card .form_header,
	.card .form_wrap,
	.card .foot{
		transform: translateY(0);
		/* opacity: 1; */
	}
	.card .form_header{
		transition-delay: .4s;
	}
	.card .form_wrap{
		transition-delay: .6s;
	}
	
	.card .foot{
		transition-delay: .8s;
	}
	
	
	.popup {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.popup_wrap {
	    width: 6.16rem;
	    padding: 0 0.74rem 0.54rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    position: relative;
	}
	.popup_box {
	    text-align: center;
	}
	.popup_tips {
	    width: 2.20rem;
	    height: 2.20rem;
	    margin-top: -0.72rem;
	}
	.popup_text {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #777F8F;
	    line-height: 0.44rem;
	    margin-top: 0.86rem;
	}
	.popup_btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin-top: 0.80rem;
	}
	
	.popup_close {
	    position: absolute;
	    bottom: -136rem;
	    left: 44%;
	    width: 0.72rem;
	    height: 0.72rem;
	}
	.close_icon {
	    width: 0.72rem;
	    height: 0.72rem;
	}
	
	.type_lab {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #8C919A;
	    margin-top: 0.44rem;
	}
	.type_val {
	    font-size: 0.44rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 600;
	    color: #32363E;
	    margin-top: 0.24rem;
	}
	
	.btn_cell {
	    display: flex;
	    width: 100%;
	    margin-top: 0.80rem;
	    justify-content: space-between;
	}
	
	.popup_btn_cal {
	    width: 47% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #6681FA;
	    background-color: #fff;
	    border-radius: 0.12rem;
	    border: 0.01rem solid #6681FA;
	    margin: 0 !important;
	}
	.popup_btn_sub {
	    width: 47% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin: 0 !important;
	}
	
</style>
